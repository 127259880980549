// class="row faq"



function Faqcontent() {

    return <>
        <div>
            <div className="row faq">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <h2 className="question faq">Frequently Asked Question</h2>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div className="faq__accordion">
                        <div className="section-title">

                        </div>
                        <div className="accordion" id="accordionExample">
                            <div className="card">
                                <div className="card-heading">
                                    <a data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" class="collapsed">
                                        Am i able to have driving lessons in my own/family or friends car ?</a>
                                </div>
                                <div id="collapseOne" className="collapse" data-parent="#accordionExample" >
                                    <div class="card-body">
                                        <p>You Must Be 16 To get learner driving license.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-heading">
                                    <a data-toggle="collapse" data-target="#collapseTwo">
                                        What type of documents are required to obtain your license??
                                    </a>
                                </div>
                                <div id="collapseTwo" className="collapse" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>You must submit proof of identity, address, age and medical fitness to apply for a license. In some cases, you may need to provide additional documentation, such as a training completion certificate and parental consent.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-heading">
                                    <a data-toggle="collapse" data-target="#collapseThree">
                                        What is the difference between an India learner’s license and a driver’s license?
                                    </a>
                                </div>
                                <div id="collapseThree" className="collapse" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>There are several key differences to note between the learner’s license and the driver’s license. The biggest difference is the purpose. A learner’s license is a temporary, introductory license that allows novice motorists to practice driving on public roadways. A driver’s license, on the other hand, is only available to motorists who have held the learner’s license and proven they are knowledgeable about road rules. As such, the steps to obtain a driver’s license include a driver’s license test on the road whereas the learner’s license only requires a written test.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-heading">
                                    <a data-toggle="collapse" data-target="#collapseFour">
                                        For how long must a motorist hold the learner’s license?
                                    </a>
                                </div>
                                <div id="collapseFour" className="collapse" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>A motorist must hold the learner’s license in India for at least 30 days before applying for the general driver’s license.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-heading">
                                    <a data-toggle="collapse" data-target="#collapseFive">
                                        Do I need a license to drive a motorcycle?
                                    </a>
                                </div>
                                <div id="collapseFive" className="collapse" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>Yes, you will need to obtain a license to ride a motorcycle in India. There are two types of motorcycle licenses: one for motorcycles with an engine up to 50cc capacity and another for motorcycles with gear. The age requirement for each is different.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="faq__accordion">
                        <div class="section-title">
                            <h2></h2>
                        </div>
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-heading">
                                    <a data-toggle="collapse" data-target="#collapsesix">
                                        What tests do you have to take to obtain a driver’s license in India? ?</a>
                                </div>
                                <div id="collapsesix" class="collapse" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>There are two mandatory tests you must pass to obtain a license: the written driver’s license test and the road skills test. The former evaluates applicants’ knowledge of India’s traffic laws, signs and safe driving techniques. The latter tests applicants’ practical driving skills and ability to drive safely on the road.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-heading">
                                    <a data-toggle="collapse" data-target="#collapseseven">
                                        How long is the written driving test?
                                    </a>
                                </div>
                                <div id="collapseseven" class="collapse" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>Typically, the test is around 50 questions with multiple-choice answers. Applicants must answer a certain number of questions correctly to successfully pass.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-heading">
                                    <a data-toggle="collapse" data-target="#collapse8">
                                        When do you take the written and road tests?
                                    </a>
                                </div>
                                <div id="collapse8" class="collapse" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>You must take the written test when applying for a learner’s license in India. Once you pass, you will hold the learner’s license for at least 30 days. After that time, you can apply for your driver’s license and schedule the road skills test.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-heading">
                                    <a data-toggle="collapse" data-target="#collapse9">
                                        When taking the road test, who supplies the vehicle?
                                    </a>
                                </div>
                                <div id="collapse9" class="collapse" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>The applicant must bring the vehicle that will be used during the road test. It must correspond with the type of India driver’s license he or she is obtaining. For example, if applying for a general driver’s license, you cannot bring a transport vehicle for the test.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-heading">
                                    <a data-toggle="collapse" data-target="#collapsefff">
                                        How do you schedule your appointment to take the tests?
                                    </a>
                                </div>
                                <div id="collapsefff" class="collapse" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>You can schedule your test online through the Ministry of Road Transport and Highways’ online portal.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="faq__accordion">
                        <div class="section-title">
                            <h2></h2>
                        </div>
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-heading">
                                    <a data-toggle="collapse" data-target="#collapsesix">
                                        What kind of questions are on India’s driver’s license test?</a>
                                </div>
                                <div id="collapsesix" class="collapse" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>The written exam will include questions testing your ability to understand parking laws, to recognize traffic signals and road markings, and to know when the use of a horn is acceptable. You can also expect situational questions that present various scenarios including what to do after a collision, when at a crosswalk and when driving on a slippery road.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-heading">
                                    <a data-toggle="collapse" data-target="#collapseseven">
                                        Can you apply for an online driving license in India?
                                    </a>
                                </div>
                                <div id="collapseseven" class="collapse" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>While you can begin your driver’s license application online, you must visit your local Road Transport Office to complete the process. The online portion involves submitting certain required documents and information.

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-heading">
                                    <a data-toggle="collapse" data-target="#collapse8">
                                        What documents do you need to apply for a driver’s license in India?
                                    </a>
                                </div>
                                <div id="collapse8" class="collapse" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>This will depend on the type of driving license status you are obtaining and your age. However, some general requirements include proof of age, address and medical fitness.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-heading">
                                    <a data-toggle="collapse" data-target="#collapse9">
                                        When should you replace your driver’s license?
                                    </a>
                                </div>
                                <div id="collapse9" class="collapse" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>You should replace your driver’s license if you have lost it or it has been stolen. You should also complete the replacement process if the license is damaged beyond legibility and can no longer serve as proof of identity.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-heading">
                                    <a data-toggle="collapse" data-target="#collapsefff">
                                        When should you renew your license?
                                    </a>
                                </div>
                                <div id="collapsefff" class="collapse" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>You can renew your driver’s license online up to 30 days before and after the expiration date. If you do not renew your license within 30 days after this date, you will incur additional fees. Furthermore, you will not be able to renew your India license if it has been expired for more than five years.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default Faqcontent;