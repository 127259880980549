
function Faq() {
    return <div>
    <div className="section-title center-title">
        <h2>Faq</h2>
        </div>
        <div className="container blog__large ">
<p>Welcome to Indian Driving License, a trustworthy site with information on licensing procedures and learn how to get your Indian Driving License directly from government with the help our guide. The information provided can be found in government sites, but in order to save time, you can purchase our guides with everything you need to know to complete your driving license for only ₹425.This site is privately owned and is neither affiliated with, nor endorsed by, nor operated by any government agency and We do not Apply Driving License. We Sell Guide and Test Book. By clicking above, you assert to be 18 years of age If you wish to start the process, Given Form In Next Step is not registration Form, its just information who is purchasing guide, please complete the form with your personal information.</p>
</div>
</div>
        
       
}
export default Faq;