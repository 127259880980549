import Home from "./pages/Home";
import Register from "./pages/Register";
import TipsforDriving from "./pages/TipsforDriving";
import TermsofService from "./pages/TermsofService";
import Disclaimers from "./pages/Disclaimers";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CancellationRefundPolicy from "./pages/CancellationRefundPolicy";

import React from "react";
import './Index.css';
import Faq from "./pages/Faq";
import { Route, Routes } from "react-router-dom";


function App() {
    return <>
        <Routes>
            <Route path="/" element={<Home />} ></Route>
            <Route path="/register" element={<Register />} ></Route>
            <Route path="/faq" element={<Faq />} ></Route>
            <Route path="/tipsforDriving" element={<TipsforDriving />} ></Route>
            <Route path="/termsofService" element={<TermsofService />} ></Route>
            <Route path="/disclaimers" element={<Disclaimers />} ></Route>
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} ></Route>
            <Route path="/CancellationRefundPolicy" element={<CancellationRefundPolicy />} ></Route>
            
            
        </Routes>



    </>;
}
export default App;