import '../css/Registersection.css';
import { useState } from "react";

function Registersection() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [Lookfor, setLookfor] = useState("");
    const [Mobile, setMobile] = useState("");
    const [Dob,setDob]= useState("");
    const [Father,setFathers]= useState("");
    const [Mother,setMother]= useState("");
    const [Residence,setResidence]= useState("");
    
    

    let handleSubmit = async (e) => {
      e.preventDefault();
      try {
        let res = await fetch("https://cfpvishesh.com/licencedrive/Api/register", {
          method: "POST",
          body: JSON.stringify({
            name: name,
            email: email,
            lookfor:Lookfor,
            mobile:Mobile,
            dob:Dob,
            fathers:Father,
            mother:Mother,
            residence:Residence,
          }),
        });
        let resJson = await res.json();
        if (res.errormsg === 200) {
          setName("");
          setEmail("");
          setLookfor("");
          setMobile("");
          setMessage("User created successfully");
        } else {
            
            setMessage("Some thing want wrong");
            
        }
      } catch (err) {
        console.log(err);
      }
    };
    return <>
        <div>
            <section className="course-details details11">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="course__details__desc">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8 col-sm-8">
                                        <div className="course__details__sidebar__register">
                                            <h5>Driving License Online Guide </h5>
                                            <form onSubmit={handleSubmit} method='post'>
                                                <span className='spancolor'>You Are Looking For</span>
                                                <select  className='form-control' onChange={(e) => setLookfor(e.target.value)}>
                                                    <option selected value="Driving license">Driving license</option>
                                                    <option value="Learner's license">Learner's license</option>
                                                    <option  value="Permanent Driving license">Permanent Driving license</option>
                                                    <option value="Driving license Status">Driving license Status</option>
                                                    <option value="Driving license Tests">Driving license Tests</option>
                                                    <option value="Change of Address in Drivers license">Change of Address in Drivers license</option>
                                                    <option value="Change of Name in Drivers license">Change of Name in Drivers license</option>
                                                    <option value="Renewal of Drivers license">Renewal of Drivers license</option>
                                                    <option value="Issue of Duplicate Drivers license">Issue of Duplicate Drivers license</option>
                                                    <option value="Motorcycle Drivers license">Motorcycle Drivers license</option>
                                                    <option value="Practice Test Guide">Practice Test Guide</option>

                                                
                                                
                                                </select><br/>
                                                <input type="text" placeholder="Name" name="name"  onChange={(e) => setName(e.target.value)}></input>
                                                <input type="email" placeholder="Email Id" name="email"  onChange={(e) => setEmail(e.target.value)}></input>
                                                <input type="phone" placeholder="Mobile Number" name="mobilenumber"  onChange={(e) => setMobile(e.target.value)}></input>
                                                <input type="date" placeholder="Date Of Birth MM/DD/YYYY" name="dob"  onChange={(e) => setDob(e.target.value)}></input>
                                                <input type="text" placeholder="Fathers’ name " name="fathers"  onChange={(e) => setFathers(e.target.value)}></input>
                                                <input type="text" placeholder="Mother’s Name" name="mother"  onChange={(e) => setMother(e.target.value)}></input>
                                                <input type="text" placeholder="Residence Address" name="residence"  onChange={(e) => setResidence(e.target.value)}></input>
                                                <input type='checkbox' required/><p>
I hereby Declare that above mentioned details are correct and I Read and Accept Terms of Service, Disclaimer and all Polices of Website</p>
                                                <button type="submit" class="site-btn second-bg" name="submit">Proceed Next</button>
                                                <div className="message">{message ? <p>{message}</p> : null}</div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                        <div className="course__details__sidebar">
<div className="course__details__sidebar__register">
<h5>INSTRUCTION </h5>
<ul>
<li className='spanmargin'><span>1. Please Select The Service you Need. सर्विस का चयन करे </span></li>
<li className='spanmargin'><span>2- Write your Name. अपना नाम लिखे </span></li>
<li className='spanmargin'> <span>3- Please Mention your Email id.  अपना ईमेल लिखे ! </span></li>
<li className='spanmargin'> <span>4- Please enter only 10 Digit mobile no. and verify the otp. सिर्फ 10 अंको का मोबाइल नंबर , और मोबाइल वेरिफिकेशन कीजिए डाले </span></li>
<li className='spanmargin'><span>5- Mention Date of Birth. अपनी जन्म तिथि डाले </span></li>

<li className='spanmargin'> <span>6- Mention your father's Name. अपना पिता का नाम डाले </span></li>

<li className='spanmargin'> <span>7- Mention Your mother's name. अपनी माता का नाम डाले </span></li>
<li className='spanmargin'> <span>8- Mention Your Residence Address. अपना पता डालेे </span></li>
</ul>
    </div></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        
    </>
}
export default Registersection;