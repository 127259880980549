
import { useNavigate } from 'react-router-dom';
import licencelogo from '../images/licencelogo.jpeg';
import '../css/Homepagecss.css';
import Disclaimer from './Disclaimer.js';
import Footer from './Footer';
function Homecontent()
{
    const navigate=useNavigate()
    return <>
<div className="center">
<h3 className="title"><img id="logoagency" src={licencelogo} className="App-logo logoagency" alt="logo"  /></h3>
</div>
<div className="hero"> 
<div className="component">
                <div className="child">
                    <div className="license1">
                        <button className="renwe buthome"  onClick={()=>navigate('/register')} >Click to Proceed Next...</button>
                    </div>
                   
        
                </div>
            </div>
            </div>
<Disclaimer/>
<Footer/>
    </>
}
export default Homecontent;