import Footer from "./Footer";
import Headers from './Headers.js';

function TipsforDriving()
{
   
    return(
        <>
              <Headers/>
              <br/>
              <section className="course-details spad">
<div className="container">
<div className="row">
 <div className="col-lg-12 col-md-12 col-sm-12">
<div className="course__details__feature">
<h5>Tips for Applying</h5>

<p className="course__details__text">Applying for a Driving Licence in India is often seen as a confusing process. The procedure involves multiple steps, and there are numerous points where applicants may become lost in the requirements. Fortunately, there are certain points that you can keep in mind in order to get through the application as easily as possible. They are as follows:
</p>

<ul>
<li><i className="fa fa-check-circle-o"></i><span> Before applying for a new Driving Licence in India, you must ensure that you meet eligibility requirements. Each licence has mandatory qualifications that may relate to age, medical fitness, education level and address.</span></li>
<li><i className="fa fa-check-circle-o"></i><span>Once you are sure that you qualify, you may begin gathering your documents to submit to your local Road Transport Office (RTO). The documentation you need will highly depend on the Driving Licence status you want to obtain. For instance, if you have never held a driver’s licence, you must first get a Learner’s Licence, which has its own required documents list</span></li>
<li><i className="fa fa-check-circle-o"></i> <span>Day limits apply in multiple instances. You must have and hold a Learner’s Licence for at least 30 days before you can apply for the permanent licence. Also, this learning licence is only valid for 180 days before it expires.</span></li>
<li><i className="fa fa-check-circle-o"></i> <span>While you cannot obtain an online driving licence in India, you can and should begin your application process online. You can submit certain documents ahead of your visit to the RTO. This can save you time during your visit and help you narrow down which documents you must bring.)</span></li>
<li><i className="fa fa-check-circle-o"></i> <span>Know which test you need to take and how to prepare. Depending on which stage you are completing within the licensing process, you may need to pass a knowledge test and/or a skills test to ensure that you fully understand India’s traffic laws.</span></li>

<li><i className="fa fa-check-circle-o"></i> <span>If you are visiting the RTO for a test, arrive ahead of time, at least 30 minutes. This will ensure that your appointment goes smoothly. While you can take the drivers licence test multiple times, it is ideal that you pass the test the first time.</span></li>

<li><i className="fa fa-check-circle-o"></i> <span>Review traffic laws and take practise tests to gauge your knowledge regularly.</span></li>
</ul>

</div>
<p className="course__details__text">Regardless of which licence you need, set up an appointment with the RTO. This helps you avoid waiting in long lines and allows you to confirm required documents and acceptable payment methods over the phone, as each office may operate differently. Some RTOs even provide printed notices with pertinent information concerning driving exams.
</p>

</div>
</div>



</div>
</section>
        <Footer/>
       
   
        
        </>
    )
}
export default TipsforDriving;