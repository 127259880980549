
import Homecontent from './Homecontent';

// import licencelogo from './licencelogo.jpg';

function Home()
{
   
    return(
        <>
        <Homecontent/>
       
   
        
        </>
    )
}
export default Home;