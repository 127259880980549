
import Footer from "./Footer";
import Headers from './Headers.js';

function CancellationRefundPolicy()
{
   
    return(
        <>
              <Headers/>
              <br/>
              <section class="course-details spad">
<div className="container">
<div className="row">
 <div className="col-lg-12 col-md-12 col-sm-12">
<div className="course__details__feature">
<h5>Refund and Cancellation</h5>


<ul>
<li><i className="fa fa-check-circle-o"></i><span> Refund of Payment-: Received Driving Licence Guide that are given to you, indiandrivinglicense.org, will only accept refunds if we fail to provide Guide For Driving License, In Time Frame of 48 working hours after mail, if any technical error faced by client while downloading after payment. Not if Data provided, wrong in any nature(Like Wrong email, phone and etc). Indiandrivinglicense.org , reserves all the right to determine a fair value of the product on return and the same shall be binding on both parties. The refund process will be initiated once we confirmation of the services not provided. In case of refund request accepted, amount will be refunded in the same mode you have paid.</span></li>
<ul>
<li><i className="fa fa-check-circle-o"></i><span>After Refund approval, It will take 9-10 working days to get refund in your bank account</span></li>
<li><i className="fa fa-check-circle-o"></i><span>Refund request can be send at helplicenseguide@gmail.com. Refund request can be made within 5 days of online application made with valid reason.</span></li>
<li><i className="fa fa-check-circle-o"></i> <span>Refunding of Guide -: You cannot Refund the guide once Recieved from our side, as in Guide in electronic format and can be saved anywhere.</span></li>
<li><i className="fa fa-check-circle-o"></i> <span>Clarification About Guide -: If you have any query about Guide, you can write us mail at helplicenseguide@gmail.com. In case we need any additional clarification about your Driving Licence Guide, our team will reach you by email.

</span></li>
<li><i className="fa fa-check-circle-o"></i> <span>Force Majeure-: https://indiandrivinglicense.org/ shall not be considered in breach of its satisfaction guarantee policy or default under any terms of service, and shall not be liable to the client for any cessation, interruption, or delay in the performance of its obligations by reason of earthquake, flood, fire, storm, lightning, drought, landslide, hurricane, cyclone, typhoon, tornado, natural disaster, act of god or the public enemy, epidemic, famine or plague, action of a court or public authority, change in law, explosion, war, terrorism, armed conflict, labor strike, lockout, boycott or similar event beyond our reasonable control, whether foreseen or unforeseen (each a "Force majeure event").

</span></li>


</ul></ul></div>
</div>



</div>
</div></section>
        <Footer/>
       
   
        
        </>
    )
}
export default CancellationRefundPolicy;