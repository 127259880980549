import Headers from './Headers.js';
import Registersection from './Registersection.js';
import Registercontent from './Registercontent.js';
import Faqcontent from './Faqcontent.js';
import Footer from './Footer.js';


function Register() {

    return <>
   <Headers/>
   <Registersection/>
   <Registercontent/>
   <Faqcontent/>
   <Footer/>
    </>
}
export default Register;