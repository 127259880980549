import { useNavigate } from 'react-router-dom';
import '../css/footer.css';
function Footer() {
    const navigate = useNavigate()
    return <div>
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="footer__widget">
                            <h5>About Us</h5>
                            <ul>
                                <li><p className="pcolor">
                                    Indian Driving License is here to help you with all of your Online Driving Licence India needs.
                                    We know it can be difficult to know how and when to complete certain tasks at your local Road Transport Office.
                                    That is why, when you visit our site, we provide detailed and simple steps on how to perform each process.
                                    Whether you want to learn how to obtain a Learner’s Licence because you are driving for the first time,
                                    or you want to obtain a Permanent Driving Licence,
                                    we have all of the information you need to complete the necessary steps easily.</p>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <div class="footer__widget">
                            <h5>Courses</h5>
                            <ul>
                                <li><a onClick={() => navigate('/')} className="footera">Home</a></li>
                                <li><a onClick={() => navigate('/faq')} className="footera">Faq</a></li>
                                <li><a onClick={() => navigate('/tipsforDriving')} className="footera">Tips for Driving</a></li>
                                <li><a onClick={() => navigate('/termsofService')} className="footera">Terms of Service</a></li>
                                <li><a onClick={() => navigate('/disclaimers')} className="footera">Disclaimer</a></li>
                                <li><a onClick={() => navigate('/PrivacyPolicy')} className="footera">Privacy Policy</a></li>
                                <li><a onClick={() => navigate('/CancellationRefundPolicy')} className="footera">Cancellation / Refund Policy</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="footer__about">
                            <div className="footer__logo">
                                <a href="#">
                                    <h5 className="indian">INDIAN DRIVING LICENSE</h5>
                                </a>
                            </div>
                            <p>Address :  Shop no 3, Sagar kunj Complex, chirawa, district- Jhunjhunu, Rajasthan- 333026</p>
                            <ul>
                                <li>Office Time :10:30 AM to 5:00 PM</li>
                                <li>Monday-Friday</li>
                                <li>Phone (Technical) :+91-9999882372</li>
                                <li>Email (For Any Other Query): <a href="#" class="__cf_email__" data-cfemail="cc87adbfa9a38caba1ada5a0e2afa3a1">helplicenseguide@gmail.com</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 text-center">

                        <div className="footer__copyright__text">
                            <p>Copyright © All rights reserved | This made with
                                <i className="fa fa-heart" aria-hidden="true"></i> by <a  onClick={()=>navigate('/')} target="_blank">Indian Driving License website</a></p>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>


}
export default Footer;