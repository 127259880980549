

function Registercontent() {

    return <>
        <div>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="course__details__title">
                        <h3 className="learning">Learning license And  Driving license</h3>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="faq__accordion">
                        <div className="section-title">
                            <h4 className="learning ">Learning license</h4>
                        </div>
                        <p className="course__details__text">
                            We provide free pick up and drop off at home or school for all
                            behind-the-wheel lessons. And, we offer a payment plan at no additional charge. Also, the
                            student will choose from one of the following driving programs: The Basic Course includes 6
                            hours of in-car driving &amp; 6 hours of in-car observation;
                        </p>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="faq__accordion">
                        <div className="section-title">
                            <h4 className="learning">Driving license</h4>
                        </div>
                        <p className="course__details__text">
                            The Advantage Plus Program includes
                            8 hours of all private lessons (no need to do any observation); The Classic Experience
                            includes 12 hours of all private lessons. All programs earn state certification and meet all
                            high school graduation requirements. We also offer 25 and 50 hours behind-the-wheel
                            programs.</p>
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default Registercontent;