import ReactDom from 'react-dom';
import './css/Bootstrap.css';
import './css/Style.css';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
ReactDom.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
    ,

    document.getElementById('root'));